<template>
	<b-modal id="assign-company-access" size="lg" title="Assign Company Access" ok-title="Assign" ref="modal"
		@cancel="onReset" @ok="handleOk" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-container fluid>
			<b-row class="mb-2">
				<b-col sm="12">
					<i class="icon-info"></i> &nbsp;
					<i>
						You are about to assign multiple company access to
						<b>{{ name }}</b> ({{ selUser.position }}). Select companies from
						the left table and add them to the right table to grant company
						visibility to this user.
					</i>
				</b-col>
			</b-row>

			<b-row>
				<b-col lg="6" md="12" sm="12">
					<SiblingCompanyTable :user="selUser" />
				</b-col>
				<b-col lg="6" md="12" sm="12">
					<SelectedCompanyTable :user="selUser" @onAddSelCompanies="addSelectedCompanies"
						@onDeleteSelCompanies="deleteSelCompanies" />
				</b-col>
			</b-row>

			<b-row class="mt-4">
				<b-col lg="6" md="12" sm="12">
					<b-form-checkbox id="save-as-template" v-model="saveCompanyAccessTemplate" name="save-as-template"
						:value="true" :unchecked-value="false">
						Save as template
					</b-form-checkbox>
				</b-col>
			</b-row>
			<b-row class="my-2" v-show="saveCompanyAccessTemplate">
				<b-col lg="6" md="12" sm="12">
					<b-card>
						<p class="card-text">
							<b-form-group label="Template Name" label-for="Template Plate" description>
								<b-form-input name="Template Name" type="text" v-model="template.name" placeholder="Template Name" 
								v-validate="{
									required: true,
									regex: nameRegex
								}" maxlength="30" />
								<span v-show="errors.has('Template Name')" class="help-block">
									{{ errors.first('Template Name') }}
								</span>
							</b-form-group>

							<b-form-group label="Description" label-for="Description" description>
								<b-form-textarea name="Description" v-model="template.description"
									placeholder="Description" rows="3" max-rows="3" v-validate="{
										required: true,
										regex: remarksRegex
									}" maxlength="200" />
								<span v-show="errors.has('Description')" class="help-block">
									{{ errors.first('Description') }}
								</span>
							</b-form-group>
						</p>
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
// Component
import SiblingCompanyTable from './SiblingCompanyTable';
import SelectedCompanyTable from './SelectedCompanyTable';

// Util
import { DateUtil } from '@/utils/dateutil';
import { UserUtil } from '@/utils/userutil';

// DAO and API
import userApi from '@/api/userApi';
import templateDAO from '@/database/companyAccessTemplates';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'assign-company-access',
	components: {
		SiblingCompanyTable,
		SelectedCompanyTable,
		Loading,
	},
	props: {
		allCompaniesObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			selUser: {},
			companyAccess: [],
			saveCompanyAccessTemplate: false,
			template: {
				name: '',
				description: '',
			},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.selUser.firstName + ' ' + this.selUser.lastName;
		},
		nameRegex() {
			return config.nameRegex;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelUser', user => {
			let userJSON = JSON.stringify(user);
			this.selUser = JSON.parse(userJSON);

			// reset template
			this.resetTemplate();

			this.initCompanyAccess(this.selUser);
		});
	},
	methods: {
		initCompanyAccess(user) {
			if (!_.isEmpty(user.companyAccess)) {
				this.companyAccess = user.companyAccess;
			} else {
				this.companyAccess = UserUtil.getDefaultCompanyAccess(
					user,
					this.allCompaniesObj
				);
			}
		},
		addSelectedCompanies(selCompaniesArr) {
			_.forEach(selCompaniesArr, (company) => {
				let existing = _.find(this.companyAccess, (o) => {
					return o.id === company.id;
				});
				if (_.isEmpty(existing)) {
					this.companyAccess.push(company);
				}
			});
		},
		deleteSelCompanies(selCompanyNamesArr) {
			_.forEach(selCompanyNamesArr, (companyName) => {
				_.remove(this.companyAccess, (o) => {
					return o.name === companyName;
				});
			});
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			if (this.companyAccess.length < 1) {
				this.$toaster.warning('At least 1 company selection is required');
				this.isLoading = false;
				return;
			}
			if (this.companyAccess.length > 250) {
				this.$toaster.warning('Allowed number of company access exceeded! Max is 250.');
				this.isLoading = false;
				return;
			}

			if (this.saveCompanyAccessTemplate) {
				let isValid = await this.$validator.validateAll();
				if (!isValid) {
					this.$toaster.warning(
						'Please address the field/s with invalid input.'
					);
					this.isLoading = false;
					return;
				}
			}

			await this.handleSubmit();
		},
		getTemplateObj(companyAccess) {
			return {
				name: this.template.name,
				description: this.template.description,
				companyId: this.loggedUserCompany.id,
				company: this.loggedUserCompany.name,
				companyAccess: companyAccess,
				isActive: 'true',
				dateCreated: DateUtil.getCurrentTimestamp(),
				createdBy: this.loggedUser.id,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			// update fields
			this.selUser.companyAccess = this.companyAccess;
			this.selUser.dateUpdated = DateUtil.getCurrentTimestamp();
			this.selUser.updatedBy = this.loggedUser.id;

			try {
				let { data } = await userApi.updateUser(
					this.selUser,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					if (this.saveCompanyAccessTemplate) {
						let templateObj = this.getTemplateObj(this.selUser.companyAccess);
						templateDAO.saveCompanyAccessTemplate(templateObj);
					}

					if (!this.isSuperAdmin && this.loggedUser.id === this.selUser.id) {
						this.updateCompanyAccess(this.companyAccess);
					}

					this.$toaster.success(data.message);
					EventBus.$emit('onCloseSaveUser', data.user);
					this.$refs.modal.hide();
				} else {
					let error = data.errors[0];
					if (
						(!_.isEmpty(error.code) &&
							error.code === 'auth/email-already-exists') ||
						error.code === 'auth/phone-number-already-exists'
					) {
						this.$toaster.error(error.message);
					} else {
						this.$toaster.error(error);
					}
				}

			} catch (_error) {
				this.$toaster.error(`Error assigning company access for user "${this.name}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		updateCompanyAccess(companyAccess) {
			let companyIds = _.map(companyAccess, 'id');

			let currCompanyId = this.loggedUserCompany.id;
			let filteredCompanies = {};

			_.forEach(this.allCompaniesObj, (companyObj, companyId) => {
				// Current Company
				if (companyId === currCompanyId) {
					filteredCompanies[companyId] = companyObj;
				}

				// Child Company
				let parentCompanyId = companyObj.parentCompanyId;
				if (parentCompanyId === currCompanyId) {
					filteredCompanies[companyId] = companyObj;
				}

				// Remaining Company Access
				if (companyIds.includes(companyId)) {
					filteredCompanies[companyId] = companyObj;
				}
			});

			this.$store.dispatch('setAllCompanies', filteredCompanies);
		},
		onReset() {
			this.selUser = {};
			this.companyAccess = [];

			this.resetTemplate();
		},
		resetTemplate() {
			this.saveCompanyAccessTemplate = false;
			this.template = {
				name: '',
				description: '',
			};
		},
	},
};
</script>